
.input-deep {
    margin: 0 12px 0 17px;
}
.option-list {
    .option-item {
        position: relative;
        width: 300px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        margin: 10px 0;
        .remove-icon {
            position: absolute;
            top: 0;
            right: -19px;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                font-size: 14px;
                color: #666;
                cursor: pointer;
            }
        }
        &::v-deep .el-checkbox__input {
            &.is-checked {
                &+.el-checkbox__label {
                    color: #1122D8;
                }
                .el-checkbox__inner {
                    background-color: #1122D8;
                    border-color: #1122D8;
                }

            }
        }
    }
}

