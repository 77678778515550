
.breadcrumb-line {
    padding: 10px 0;
}
.topic-content {
    width: 100%;
    height: calc(100% - 34px);
    &::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
}
.topic-main {
    padding: 20px 0;
    width: 100%;
    .topic-form-item {
        display: flex;
        align-items: center;
        margin-bottom: 22px;
        .form-label {
            width: 120px;
            text-align: right;
            padding-right: 12px;
            em {
                color: #F56C6C;
                margin-right: 2px;
            }
        }
    }
    &::v-deep .el-radio-button__orig-radio {
        &:checked+.el-radio-button__inner {
            background-color: #1122D8;
            border-color: #1122D8;
        }
    }
    .form-submit-btns {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &::v-deep .el-form-item {
        .el-switch {
            &.is-checked {
                .el-switch__core {
                    background-color: #1122D8;
                    border-color: #1122D8;
                }
            }
        }
    }
}

